var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-100 bg-white",staticStyle:{"margin-right":"56px"}},[_c('BContainer',{staticClass:"h-100 justify-content-center d-flex"},[_c('BRow',[_c('BCol',{staticClass:"col500 mx-auto"},[_c('BRow',{staticClass:"text-center py-3 mt-4"},[_c('BCol',{staticClass:"pt-2 text-center",attrs:{"cols":"1"}},[_c('a',{staticClass:"pl-3",attrs:{"href":_vm.app == 'milk'
                  ? '/milk-settings/tab/data-forms'
                  : '/fuel-settings/tab/data-forms'}},[_c('BIcon',{attrs:{"icon":"chevron-left","scale":"1.2"}})],1)]),_c('BCol',[_c('h3',{staticClass:"text-center"},[_vm._v("Add Field")])]),_c('BCol',{attrs:{"cols":"1"}})],1),_c('BForm',{attrs:{"onsubmit":"return false"},on:{"submit":_vm.onSubmit}},[_c('p',{staticClass:"inputBlockLabel pb-0"},[_vm._v("Edit field data")]),_c('FormTextInput',{attrs:{"inputAttrs":{
              id: 'name',
              label: 'Form field name',
              labelClass: 'text-primary',
              type: 'text',
              variant: _vm.bootstrapColour
            },"id":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('FormDropdownInput',{attrs:{"inputAttrs":{
              id: 'type',
              label: 'Type',
              options: ['Text entry', 'Numeric text entry'],
              variant: _vm.bootstrapColour
            },"id":"type"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('BButton',{staticClass:"d-flex mx-auto text-white mt-4 px-5",attrs:{"type":"submit","variant":_vm.bootstrapColour}},[_vm._v("Save")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }