
import { Component, Vue } from 'vue-property-decorator';

import FormTextInput from '@/components/common/FormTextInput.vue';
import FormDropdownInput from '@/components/common/FormDropdownInput.vue';
import { trackEvent } from '@/services/Mixpanel';
import { getObjectListItem, setObjectListItem } from '@/utils/LocalStorageUtils';
import AppName from '@/components/mixin/AppName.vue';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    FormTextInput,
    FormDropdownInput
  }
})
export default class AddFieldDataForm extends mixins(AppName) {
  public form = {
    name: '',
    type: '',
    required: false,
    disable: false,
    custom: true
  };
  public attemptSubmit: boolean | null = null;
  public submitted = false;
  public unresolvableError = false;
  public errorMessage = 'Missing fields';
  public errorOccurred = false;
  public app = 'milk';
  public colour = '#F6861F';
  public bootstrapColour = 'secondary';

  mounted() {
    trackEvent('User viewing add field data form');

    this.app = this.getAppName(false);
    this.colour = this.getAppColour(false);
    this.bootstrapColour = this.getAppColour(true);
  }

  public async onSubmit(): Promise<void> {
    if (this.form.name != '' && this.form.type != '') {
      const formFieldsFromItem = getObjectListItem(`${this.app}FormFields`);
      if (formFieldsFromItem && formFieldsFromItem.length != 0) {
        formFieldsFromItem.push(this.form);
        setObjectListItem(`${this.app}FormFields`, formFieldsFromItem);
        this.$router.push('/milk-settings/tab/data-forms');
      }
    }
  }
}
